import gql from 'graphql-tag'

export const ShortcutFields = gql`
    fragment ShortcutFields on Shortcut {
      id
      label
      description
      taskStatus
      topic {
        id
      }
      subtopic {
        id
      }
      taskRecipientUser {
        id
      }
      taskRecipientGroup {
        id
      }
    }
  `
