<template>
  <Teleport to="body">
    <SuiDimmer
      v-if="isPrompting || (!optional && !isGranted)"
      active
      page
    >
      <SuiHeader
        v-if="isPrompting"
        as="h2"
        icon
        inverted
      >
        <SuiHeaderContent>
          Zur Nutzung dieser Funktion bitte den Zugriff auf {{ objectNames }} erlauben
        </SuiHeaderContent>
      </SuiHeader>
      <div v-else-if="isDenied || isError">
        <SuiHeader
          as="h2"
          icon
          inverted
        >
          <SuiIcon
            name="frown outline"
          />
          <SuiHeaderContent v-if="isDenied">
            Der Zugriff auf {{ objectNamesDenied }} wurde verwert.
          </SuiHeaderContent>
          <SuiHeaderContent v-if="isError">
            Beim Zugriff auf {{ objectNamesError }} ist ein Fehler aufgetreten.
          </SuiHeaderContent>
          <SuiHeaderSubheader>
            <slot />
          </SuiHeaderSubheader>
        </SuiHeader>
        <div>
          <SuiButton @click="() => requestPermissions()">
            Erneut versuchen
          </SuiButton>
        </div>
      </div>
      <SuiLoader v-else />
    </SuiDimmer>
  </Teleport>
</template>
<script setup>
import {
  SuiIcon,
  SuiDimmer,
  SuiHeader,
  SuiButton,
  SuiLoader,
  SuiHeaderContent,
  SuiHeaderSubheader
} from 'vue-fomantic-ui'
import { onMounted, computed, ref, toRaw } from 'vue'
import PermissionHandler from '@/js/PermissionHandler.js'

const emit = defineEmits(['change', 'granted', 'denied', 'error', 'requested'])
const permissionHandlerObj = ref(null)
const isPrompting = ref(false)
const isGranted = ref(false)
const isDenied = ref(false)
const isError = ref(false)

const props = defineProps({
  permissions: {
    type: Object,
    required: true
  },
  optional: {
    type: Boolean,
    required: false,
    default: false
  },
  request: {
    type: Boolean,
    required: false,
    default: true
  },
  retryable: {
    type: Array,
    required: false,
    default: () => []
  }
})

function capitalizeFirstLetter (val) {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1)
}

const requestPermissions = async () => {
  const handler = toRaw(permissionHandlerObj.value)
  await handler.request()
  emit('requested', handler.getPermissions())
}

const objectNames = computed(() => getObjectsNames())
const objectNamesDenied = computed(() => getObjectsNames('denied'))
const objectNamesError = computed(() => getObjectsNames('error'))

const getObjectsNames = (stateFilter = null) => {
  const handler = toRaw(permissionHandlerObj.value)
  const names = []

  for (const [type, state] of Object.entries(handler.getPermissions())) {
    if (stateFilter === null || state === stateFilter) {
      names.push(getDisplayName(type))
    }
  }

  return names.join(', ').replace(/,\s+([^,\s]*)$/, ' und $1')
}

const getDisplayName = (type) => {
  switch (type) {
    case 'camera':
      return 'die Kamera'
    case 'microphone':
      return 'das Mikrofon'
    case 'printer':
      return 'den Drucker'
    default:
      return capitalizeFirstLetter(type)
  }
}

onMounted(async () => {
  permissionHandlerObj.value = new PermissionHandler({
    request: props.request,
    permissions: props.permissions,
    retryable: props.retryable,
    onChanged: ({
      data,
      granted,
      error,
      prompting,
      denied
    }) => {
      isGranted.value = granted
      isError.value = error
      isPrompting.value = prompting
      isDenied.value = denied

      emit('change', data)
      if (granted) {
        emit('granted', data)
      } else if (denied) {
        emit('denied', data)
      } else if (error) {
        emit('error', data)
      }
    }
  })

  await requestPermissions()
})
</script>
