import $ from 'jquery'

/**
 * Theoretically, if the selection covers the whole element, it would make sense
 * to split the selection into halves: before and after the element. But
 * multiple selection ranges are supported only in Firefox as for now.
 *
 * Further if the selection starts in the element and continues after it,
 * Chrome still keeps the element selected after Range.setStartAfter(element).
 *
 * So just discard the selection after the element.
 *
 * @param {Selection} selection - Current selection.
 * @param {Node} element - The element to be removed from the selection.
 */
export function excludeNodeFromRange (selection, element) {
  for (let i = selection.rangeCount - 1; i >= 0; --i) {
    const range = selection.getRangeAt(i)

    if ($(range.commonAncestorContainer).closest(element).length !== 0) {
      selection.removeRange(range)
    } else if (range.intersectsNode(element)) {
      if (range.comparePoint(element, 0) >= 0) {
        range.setEndBefore(element)
      } else {
        range.collapse(false)
      }
    }
  }
}

$(function () {
  $('.map-container').mousedown(function () {
    excludeNodeFromRange(document.getSelection(), this)
  })
})
