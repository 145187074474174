import gql from 'graphql-tag'
import { ShortcutFields } from '@/graphql/queries/shortcutFragment.js'

const GetUserShortcuts = gql`
  query GetUserShortcuts {
    forUserShortcuts {
      ...ShortcutFields
      iAmOwner
    }
  }
  ${ShortcutFields}
`

export default GetUserShortcuts
