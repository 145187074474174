<template>
  <div>
    <SuiModal
      v-model="localShowModal"
      :closable="false"
    >
      <SuiModalHeader>
        <SuiIcon name="home" /> <span>Gebäude-Adressbuch</span>
      </SuiModalHeader>
      <SuiModalContent>
        <ErrorMessage :error="error" />
        <div v-if="!selectedBuilding">
          <div class="center">
            <sui-input
              v-model="search"
              placeholder="Suche..."
              icon="search"
              :loading="loading"
              @input="debounceSearch"
            />
          </div>
          <building-search-results
            :options="options"
            :search-value="search"
            :is-typing="isTyping"
            @building-selected="buildingSelectedInSearch"
          />
        </div>
        <div v-else>
          <show-building :building="selectedBuilding" />
        </div>
      </SuiModalContent>
      <SuiModalActions class="BuildingSelection-actions">
        <SuiButton
          v-if="selectedBuilding"
          primary
          @click="selectedBuilding = null"
        >
          Zurück zur Übersicht
        </SuiButton>
        <SuiButton
          id="building-addressbook-abort"
          positiv
          class="orange"
          @click="abort"
        >
          Abbrechen
        </SuiButton>
        <SuiButton
          v-if="selectedBuilding"
          primary
          @click.prevent="buildingConfirmed"
        >
          {{ initConfirmText ? initConfirmText : 'Gebäude wählen' }}
        </SuiButton>
      </SuiModalActions>
    </SuiModal>
  </div>
</template>

<script setup>
import {
  SuiButton,
  SuiIcon,
  SuiInput,
  SuiModal,
  SuiModalActions,
  SuiModalContent,
  SuiModalHeader
} from 'vue-fomantic-ui'
import { ref, watch } from 'vue'
import BuildingSearchResults from '@/components/_partials/Organism/BuildingSearchResults.vue'
import ShowBuilding from '@/components/_partials/Organism/ShowBuilding.vue'
import { path, request } from '@/js/request.js'
import { API_V1_BUILDING_SEARCH } from 'routes'
import ErrorMessage from '@/components/_partials/Atom/ErrorMessage.vue'

const props = defineProps({
  showModal: {
    type: Boolean,
    default: false
  },
  initConfirmText: {
    type: String,
    default: ''
  },
  initCallback: {
    type: String,
    default: null
  },
  initSearchUrl: {
    type: String,
    default: path(API_V1_BUILDING_SEARCH, { search: '' })
  },
  initSearch: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['closeModal', 'buildingSelected'])

const selectedBuilding = ref(null)
const localShowModal = ref(false)
const search = ref('')
const error = ref(null)
const loading = ref(false)
const isTyping = ref(false)
const options = ref([])
const debounce = ref(null)

function abort () {
  reset()
  emit('closeModal')
}

function buildingSelectedInSearch (building) {
  selectedBuilding.value = building
}

function buildingConfirmed () {
  emit('buildingSelected', selectedBuilding.value)
  if (props.initCallback) {
    callCallBack(selectedBuilding.value)
  }
  reset()
  emit('closeModal')
}

function callCallBack (building) {
  const cur = new URL(window.location.href)
  const target = new URL(props.initCallback, cur.protocol + '//' + cur.host)
  target.searchParams.append('buildingID', building.id)
  window.location = target
}

watch(() => props.showModal, (newVal) => {
  localShowModal.value = newVal
})

fallbackToInitialSearch()

function fallbackToInitialSearch () {
  if (props.initSearch.length >= 3) {
    search.value = props.initSearch
    fetchOptions()
  }
}

async function debounceSearch () {
  options.value = []
  isTyping.value = true
  clearTimeout(debounce.value)
  if (search.value.length >= 3) {
    debounce.value = setTimeout(() => {
      fetchOptions()
    }, 500)
  }
}

function fetchOptions () {
  loading.value = true
  request(props.initSearchUrl + search.value).then(({ json }) => {
    options.value = json
    isTyping.value = false
    loading.value = false
  }).catch((e) => {
    loading.value = false
    error.value = e
  })
}

function reset () {
  options.value = []
  search.value = ''
  selectedBuilding.value = null
  fallbackToInitialSearch()
}
</script>
