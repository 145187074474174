import gql from 'graphql-tag'

const DeleteOwnShortcut = gql`
  mutation DeleteOwnShortcut($id: ID!) {
    deleteShortcut(input: {
      id: $id
    }) {
      shortcut {
        id
      }
    }
  }
`

export default DeleteOwnShortcut
