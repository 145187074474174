<template>
  <SuiMessage
    v-for="message in messages"
    :key="message"
    negative
    header="Fehler"
    :content="message"
  />
</template>
<script setup>
import { SuiMessage } from 'vue-fomantic-ui'
import { computed } from 'vue'

const props = defineProps({
  error: {
    type: [String, Object, Array],
    default: null
  }
})

function convert (error) {
  let message = null

  if (error !== null) {
    message = ''

    if (typeof error === 'string' || error instanceof String) {
      message = error
    } else if (typeof error === 'object' && 'message' in error) {
      message = error.message

      if (message.length === 0) {
        message = error.toString()
      }
    } else {
      message = JSON.stringify(error)
    }

    if (message.length === 0) {
      message = 'Error'
    }
  }

  return message
}

const messages = computed(() => {
  const items = []
  let errors = props.error

  if (!Array.isArray(errors)) {
    errors = [errors]
  }

  for (const error of errors) {
    if (error === null) {
      continue
    }
    items.push(convert(error))
  }

  return items
})
</script>
