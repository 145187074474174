<template>
  <div class="unit-selection">
    <ErrorMessage :error="error" />
    <sui-dropdown
      v-if="!error"
      v-model="selectedUnit"
      :options="options"
      :disabled="options.length <= 1"
      selection
    />
  </div>
</template>

<script setup>
import { path, useRequest } from '@/js/request.js'
import { SuiDropdown } from 'vue-fomantic-ui'
import * as R from 'ramda'
import { ref, watch } from 'vue'
import { API_V1_BUILDING_GET, UNIT_DETAIL } from 'routes'
import ErrorMessage from '@/components/_partials/Atom/ErrorMessage.vue'

const props = defineProps({
  buildingId: {
    type: Number,
    required: true
  },
  unitId: {
    type: Number,
    required: true
  }
})

const options = ref([])
const selectedUnit = ref(null)

const { response, error } = useRequest({
  route: API_V1_BUILDING_GET,
  params: {
    id: props.buildingId
  }
})

watch(response, ({ json }) => {
  const newOptions = []
  const defaultToDash = R.defaultTo('–')
  const commonClasses = 'small circular'

  json.units.forEach((unit) => {
    const option = {
      text: `${defaultToDash(unit.location_in_building)} (${unit.home_id})`,
      icon: unit.verified
        ? `${commonClasses} check c-background--teal`
        : `${commonClasses} question c-background--grey`,
      value: unit.id
    }
    newOptions.push(option)
  })

  newOptions.forEach((option) => {
    if (option.value === props.unitId) {
      selectedUnit.value = option
    }
  })

  options.value = newOptions
})

watch(selectedUnit, (value) => {
  if (value.value !== props.unitId) {
    window.location.assign(path(UNIT_DETAIL, { unit: value.value }))
  }
})
</script>

<style scoped>
.unit-selection {
  min-width: 40%;
  float: right;
}
</style>
