<template>
  <div>
    <label>Shortcuts</label>
    <br>
    <div
      v-for="shortcut in shortcuts"
      :key="shortcut.id"
      class="ShortcutList-shortcut"
    >
      <a
        style="white-space: nowrap"
        @click.prevent="onClickShortcut(shortcut)"
      >
        {{ shortcut.label }}
      </a>
      <span v-if="shortcut.iAmOwner">|</span>
      <SuiIcon
        v-if="shortcut.iAmOwner"
        name="pencil"
        class="ShortcutList-shortcut-icon"
        size="small"
        fitted
        @click="editShortcut = shortcut"
      />
    </div>
    <div class="ShortcutList-shortcut">
      <SuiIcon
        name="plus"
        class="ShortcutList-shortcut-icon"
        @click="addShortcut"
      />
    </div>
    <ShortcutEditDialog
      v-model="editShortcut"
      :user-id="props.userId"
      @close-modal="closeModal"
    />
  </div>
</template>
<script setup>
import GetUserShortcuts from '@/graphql/queries/getUserShortcuts.js'
import { useQuery } from '@vue/apollo-composable'
import { ref, watch } from 'vue'
import ShortcutEditDialog from '@/components/History/ShortcutEditDialog.vue'
import { SuiIcon } from 'vue-fomantic-ui'
import { executeShortcut } from '@/js/task.js'
import { getIdFromIri } from '@/js/utils/graphQL.js'

const props = defineProps({
  userId: {
    type: Number,
    required: true
  }
})

const { result, refetch } = useQuery(GetUserShortcuts)

const shortcuts = ref([])
const editShortcut = ref(null)

watch(result, (newResult) => {
  if (newResult && newResult.forUserShortcuts) {
    shortcuts.value = newResult.forUserShortcuts
  }
})

function onClickShortcut (shortcut) {
  executeShortcut(
    'task',
    shortcut.description,
    shortcut.taskStatus,
    getIdFromIri(shortcut.topic?.id),
    getIdFromIri(shortcut.subtopic?.id),
    getIdFromIri(shortcut.taskRecipientUser?.id),
    getIdFromIri(shortcut.taskRecipientGroup?.id)
  )
}

function addShortcut () {
  editShortcut.value = {
    id: null,
    label: null,
    description: null,
    taskStatus: null,
    topic: null,
    subtopic: null,
    taskRecipientUser: null,
    taskRecipientGroup: null,
    iAmOwner: true
  }
}

function closeModal () {
  editShortcut.value = null
  refetch()
}
</script>
