<template>
  <button
    :class="buttonClasses"
    @click.prevent="openWindow"
  >
    <i
      v-if="icon !== ''"
      :class="'icon ' + icon"
    />
    {{ text }}
  </button>
</template>

<script>
import { fenster } from '@/js/project'

export default {
  tag: 'window-button',
  props: {
    title: { type: String, default: 'Titel' },
    text: { type: String, default: '' },
    link: { type: String, default: '' },
    icon: { type: String, default: '' },
    classes: { type: String, default: '' },
    fullsize: { type: Boolean, default: false }
  },
  data () {
    let buttonClasses = 'ui primary button '
    buttonClasses += this.classes

    if (this.fullsize) {
      buttonClasses += ' fullSize'
    }

    if (this.icon) {
      buttonClasses += ' labeled icon'
    }

    return {
      buttonClasses
    }
  },
  methods: {
    openWindow () {
      fenster(this.link, this.title, 1040, 1040)
    }
  }
}
</script>
