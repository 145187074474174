export default class PermissionHandler {
  currentPermissions = {}
  shouldRequest = null
  checks = {}
  permissions = {}
  retryable = {}
  handlers = {}
  onChanged

  constructor ({
    request,
    permissions,
    retryable,
    handlers = {},
    onChanged = null
  }) {
    if (onChanged === null) {
      onChanged = () => {}
    }

    this.shouldRequest = request
    this.onChanged = onChanged
    this.retryable = retryable
    this.permissions = permissions

    const mediaHandler = () => {
      return new Promise((resolve, reject) => {
        navigator.mediaDevices.getUserMedia({
          audio: 'microphone' in this.permissions,
          video: 'camera' in this.permissions
        }).then(() => {
          resolve('granted')
        }).catch((e) => {
          let name = e
          if (e instanceof DOMException) {
            name = e.name
          }

          if (name.indexOf('NotAllowedError') !== -1) {
            resolve('denied')
          } else {
            reject(name)
          }
        })
      })
    }

    this.handlers = {
      ...{
        camera: mediaHandler,
        microphone: mediaHandler,
      },
      ...handlers
    }

    for (const type of Object.keys(permissions)) {
      this.#setState(type, 'unknown')
    }
  }

  getPermission (type) {
    return this.currentPermissions[type]
  }

  getPermissions () {
    return JSON.parse(JSON.stringify(this.currentPermissions))
  }

  async request () {
    if (Object.keys(this.checks).length === 0) {
      return await this.#start()
    } else {
      return await this.#retry()
    }
  }

  async #start () {
    return await this.#executeOrderly((type) => {
      this.checks[type] = this.#registerCheck(type)
      return this.checks[type]
    })
  }

  async #retry () {
    for (const [key, value] of Object.entries(this.currentPermissions)) {
      if ((value === 'error' || value === 'denied') && this.retryable.indexOf(key) === -1) {
        window.location.reload()
        return
      }
    }

    await this.#executeOrderly(async (type) => {
      const state = this.getPermission(type)
      if ((state === 'error' || state === 'denied') && this.retryable.indexOf(type) !== -1) {
        await this.#requestPermission(type)
      }
    })
  }

  #setState (key, value) {
    if (this.currentPermissions[key] !== value) {
      this.currentPermissions[key] = value

      const data = this.getPermissions()
      let prompting = Object.values(data).length > 0
      let granted = Object.values(data).length > 0
      let denied = false
      let error = false

      Object.values(data).forEach((value) => {
        error = error || value === 'error'
        denied = denied || value === 'denied'
        prompting = prompting && value === 'prompt'
        granted = granted && value === 'granted'
      })

      this.onChanged({
        data,
        error,
        denied,
        prompting,
        granted
      })
    }
  }

  async #executeOrderly (toBeExecutedFunction) {
    for (const [type, callback] of Object.entries(this.permissions)) {
      if (callback === true) {
        await toBeExecutedFunction.call(this, type)
      }
    }

    for (const [type, callback] of Object.entries(this.permissions)) {
      if (callback instanceof Function || typeof callback === 'function') {
        await toBeExecutedFunction.call(this, type)
      }
    }
  }

  #registerCheck (type) {
    this.#setState(type, 'unknown')
    return new Promise((resolve) => {
      const resolveState = (state) => {
        if (state === 'granted' || state === 'error' || state === 'denied') {
          resolve()
        }
      }

      if (this.permissions[type] instanceof Function || typeof callback === 'function') {
        this.#requestPermission(type).then(() => {
          resolve()
        }).catch(() => {
          this.#setState(type, 'error')
          resolve()
        })
      } else if ('permissions' in navigator && 'query' in navigator.permissions) {
        navigator.permissions.query({
          name: type
        }).then((status) => {
          status.onchange = () => {
            this.#setState(type, status.state)
            resolveState(status.state)
          }
          this.#setState(type, status.state)

          if (this.shouldRequest && status.state !== 'granted') {
            // eslint-disable-next-line promise/no-nesting
            this.#requestPermission(type).then((state) => {
              resolveState(state)
            // eslint-disable-next-line promise/no-nesting
            }).catch((e) => {
              this.#setState(type, 'error')
              resolve()
            })
          }

          resolveState(status.state)
        }).catch(() => {
          this.#setState(type, 'error')
          resolve()
        })
      } else if (this.shouldRequest) {
        this.#requestPermission(type).then((state) => {
          resolveState(state)
        }).catch((e) => {
          this.#setState(type, 'error')
          resolve()
        })
      }
    })
  }

  #requestPermission (type) {
    let handlerFunction
    if (this.permissions[type] instanceof Function || typeof this.permissions[type] === 'function') {
      handlerFunction = this.permissions[type]
    } else {
      handlerFunction = this.handlers[type]
    }

    if (typeof handlerFunction === 'undefined') {
      this.#setState(type, 'error')
      return Promise.reject(new Error('no handler'))
    }

    this.#setState(type, 'unknown')
    return new Promise((resolve, reject) => {
      const result = handlerFunction()
      result.then((state) => {
        if (state === undefined) {
          state = 'granted'
        }
        this.#setState(type, state)
        resolve(state)
      }).catch(() => {
        this.#setState(type, 'error')
        reject(new Error('error'))
      })
    })
  }
}
