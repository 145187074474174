<template>
  <Message
    v-if="error"
    negative
    header="Fehler"
    :content="typeof error === 'object' && 'message' in error ? error.message : error"
  />
  <DocumentList
    :placeholder="placeholder"
    :allow-remove="allowRemove"
    :documents="documents"
    @remove="({document, resolve, reject}) => onRemove(document, resolve, reject)"
  />
  <div
    v-if="allowUpload"
    class="Documents-upload"
  >
    <slot />
    <UploadComponent
      :server="api"
      :style="{'margin-top':'1.75em'}"
      @processfile="onProcessFile"
      @addfile="onAddFile"
    />
  </div>
  <ConfirmDialog
    ref="confirm"
    title="Datei entfernen"
    confirm="Ja, löschen!"
  />
</template>
<script setup>
import { Message } from 'vue-fomantic-ui'
import DocumentList from '@/components/_partials/Organism/DocumentList.ce.vue'
import { request, path } from '@/js/request'
import { ref, useTemplateRef } from 'vue'
import ConfirmDialog from '@/components/_partials/Organism/ConfirmDialog.ce.vue'
import UploadComponent from '@/components/Document/UploadComponent.vue'
import {
  API_V1_DOCUMENT_CREATE,
  API_V1_DOCUMENT_LIST,
  DOCUMENT_DOWNLOAD,
  DOCUMENT_SHOW_CONTENT,
  DOCUMENT_SHOW_THUMBNAIL,
  API_V1_DOCUMENT_DELETE
} from 'routes'

const props = defineProps({
  type: {
    type: String,
    required: true
  },
  id: {
    type: Number,
    required: true
  },
  placeholder: {
    type: Number,
    required: false,
    default: 1
  },
  allowRemove: {
    type: Boolean,
    required: false,
    default: false
  },
  allowUpload: {
    type: Boolean,
    required: false,
    default: true
  }
})

const api = path(API_V1_DOCUMENT_CREATE, {
  relation_type: props.type,
  relation: props.id
})
const $emit = defineEmits(['file'])
const confirm = useTemplateRef('confirm')
const placeholder = ref(props.placeholder)
const documents = ref([])
const error = ref(null)

function refresh () {
  return request({
    route: API_V1_DOCUMENT_LIST,
    params: {
      relation_type: props.type,
      relation: props.id
    }
  })
    .then(({ json }) => {
      documents.value = json.map(file => {
        return {
          ...file,
          src: path(DOCUMENT_SHOW_CONTENT, { document: file.id }),
          thumbnail: path(DOCUMENT_SHOW_THUMBNAIL, { document: file.id }),
          download: path(DOCUMENT_DOWNLOAD, { document: file.id })
        }
      })
      placeholder.value = 0

      return documents.value
    }).catch((e) => {
      error.value = e
      documents.value = []
      throw e
    })
}

function onRemove (file, resolve, reject) {
  confirm.value
    .ask("Sind Sie sicher, dass Sie die Datei '" + file.name + "' löschen wollen? " +
          'Dies kann nicht rückgängig gemacht werden!')
    .then(() => {
      placeholder.value = documents.value.length ? documents.value.length - 1 : props.placeholder
      return request({
        method: 'DELETE',
        route: API_V1_DOCUMENT_DELETE,
        params: {
          relation_type: props.type,
          relation: props.id,
          document: file.id
        }
      })
    })
    .catch((e) => {
      error.value = e
    })
    .finally(() => refresh())
    .then(() => resolve())
    .catch(() => reject())
}

function onAddFile (e, file) {
  if (e) {
    error.value = e
    return
  }

  error.value = null
  $emit('file', file)
}

function onProcessFile (e, file) {
  if (e !== null) {
    if ('code' in e) {
      error.value = e.body
    } else {
      error.value = e
    }
    return
  }

  error.value = null
  placeholder.value = documents.value.length ? documents.value.length + 1 : props.placeholder
  refresh()
}

refresh()
</script>
