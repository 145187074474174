<template>
  <i
    :class="classNames"
    :title="props.title"
  />
</template>
<script setup>
import { computed } from 'vue'

const props = defineProps({
  name: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: ''
  },
  background: {
    type: String,
    default: ''
  },
  color: {
    type: String,
    default: ''
  },
  title: {
    type: String,
    default: ''
  }
})

const classNames = computed(function () {
  const additional = []

  if (props.background.length > 0) {
    additional.push('c-background--' + props.background)
  }

  if (props.type.length > 0) {
    additional.push(props.type)
  }

  if (props.color.length > 0) {
    additional.push(props.color)
  }

  return 'icon ' + props.name + ' ' + additional.join(' ')
})
</script>
