import { request } from '@/js/request'
import { createTitleBar } from '@/js/tooltip.js'
import { API_V1_PERSON_UPDATE, API_V1_UNIT_DELETE, API_V1_UNIT_UPDATE } from 'routes'

export function handleDragStart (event) {
  event.target.style.border = '2px dashed #080878'
}
export function handleDragEnd (event) {
  event.target.style.border = 'none'
}
export async function updatePerson (person, buildingStore) {
  await request({
    method: 'PUT',
    route: API_V1_PERSON_UPDATE,
    params: {
      id: person.id
    },
    json: person
  })

  buildingStore.buildingData.people = buildingStore.buildingData.people.map(p =>
    p.id === person.id ? { ...p, ...person } : p
  )
}

export async function updateUnit (unit, buildingStore) {
  await request({
    method: 'PUT',
    route: API_V1_UNIT_UPDATE,
    params: {
      id: unit.id
    },
    json: unit
  })

  // Update the store after the request
  buildingStore.buildingData.units = buildingStore.buildingData.units.map(u =>
    u.id === unit.id ? { ...u, ...unit } : u
  )
}

export async function deleteUnit (unit, buildingStore) {
  await request({
    method: 'POST',
    route: API_V1_UNIT_DELETE,
    params: {
      id: unit.id
    },
    json: unit
  })

  buildingStore.buildingData.units = buildingStore.buildingData.units.filter(u => u.id !== unit.id)
}

function createTooltipLine (term, definition) {
  const fragment = document.createElement('div')

  const termNode = document.createElement('dt')
  termNode.appendChild(document.createTextNode(term))

  const definitionNode = document.createElement('dd')
  definitionNode.appendChild(document.createTextNode(definition))

  fragment.appendChild(termNode)
  fragment.appendChild(definitionNode)
  return fragment
}

export function createConnectivityContent (connectivities) {
  const wrapper = document.createElement('div')

  wrapper.classList.add('qtip-bootstrap')
  wrapper.appendChild(createTitleBar('Vorläufig'))

  for (const connectivity of connectivities) {
    const content = document.createElement('div')
    const dl = document.createElement('dl')

    content.classList.add('qtip-content', 'qtip-service')

    if (connectivity.full_name) {
      content.appendChild(document.createTextNode(`${connectivity.full_name}:`))
    }
    content.appendChild(dl)

    if (connectivity.fiber) {
      dl.appendChild(createTooltipLine('Faser:', connectivity.fiber))
    }
    if (connectivity.dslam_port_number) {
      dl.appendChild(createTooltipLine('DSLAM/Port:', `${connectivity.ip} #${connectivity.dslam_port_number}`))
    }
    if (connectivity.port) {
      dl.appendChild(createTooltipLine('Switch/Karte/Port:',
        `${connectivity.fqdn}/${connectivity.card}/${connectivity.port}`))
    }

    wrapper.appendChild(content)
  }
  return wrapper
}

export function createOntContent (ont) {
  const wrapper = document.createElement('div')

  wrapper.classList.add('qtip-bootstrap')
  wrapper.appendChild(createTitleBar('ONT'))

  const content = document.createElement('div')
  const dl = document.createElement('dl')

  content.classList.add('qtip-content')

  const fullName = ont.full_name
  const ontMac = ont.mac
  if (fullName) {
    content.appendChild(document.createTextNode('Person: '))
    content.appendChild(document.createTextNode(fullName))
  }
  content.appendChild(dl)

  if (ontMac) {
    const ontValue = fullName ? ontMac : ontMac + ' (direkt)'
    dl.appendChild(createTooltipLine('ONT-MAC:', ontValue))
  }
  wrapper.appendChild(content)
  return wrapper
}
