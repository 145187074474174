import gql from 'graphql-tag'
import { ShortcutFields } from '@/graphql/queries/shortcutFragment.js'

const CreateOwnShortcut = gql`
  mutation CreateOwnShortcut(
    $label: String!, 
    $description: String!, 
    $taskStatus: TaskStatus!, 
    $topicId: String, 
    $subtopicId: String,
    $taskRecipientUser: String,
    $taskRecipientGroup: String
  ) {
    createOwnShortcut(
      input: { 
        label: $label, 
        description: $description, 
        taskStatus: $taskStatus, 
        topic: $topicId, 
        subtopic: $subtopicId,
        taskRecipientUser: $taskRecipientUser,
        taskRecipientGroup: $taskRecipientGroup,
      }
    ) {
      shortcut {
        ...ShortcutFields
      }
    }
  }
  ${ShortcutFields}
`

export default CreateOwnShortcut
