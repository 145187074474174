import gql from 'graphql-tag'
import { ShortcutFields } from '@/graphql/queries/shortcutFragment.js'

const UpdateShortcut = gql`
  mutation UpdateShortcut(
    $id: ID!, 
    $label: String!, 
    $description: String!, 
    $taskStatus: TaskStatus!, 
    $topicId: String, 
    $subtopicId: String,
    $taskRecipientUser: String,
    $taskRecipientGroup: String
  ) {
    updateShortcut(
      input: { 
        id: $id, 
        label: $label, 
        description: $description, 
        taskStatus: $taskStatus, 
        topic: $topicId, 
        subtopic: $subtopicId ,
        taskRecipientUser: $taskRecipientUser,
        taskRecipientGroup: $taskRecipientGroup
      }
    ) {
      shortcut {
        ...ShortcutFields
      }
    }
  }
  ${ShortcutFields}
`

export default UpdateShortcut
